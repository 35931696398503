import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format'

const styles = theme => ({
  tooltip: {
    position: 'absolute',
    margin: '8px',
    padding: '4px',
    background: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    maxWidth: '300px',
    fontSize: '12px',
    zIndex: 9,
    pointerEvents: 'none'
  },
  image: {
    position: 'absolute',
    // margin: '8px',
    // padding: '4px',
    background: 'rgba(0, 0, 0, 0)',
    color: 'rgba(0, 0, 0, 0)',
    width: '300px',
    zIndex: 9,
    pointerEvents: 'none'
  }
})

const imageUrls = {
  // '1022': 'https://s3.ca-central-1.amazonaws.com/c-core-public/ship-and-bergs-demo/Boat1.png',
  '1022': '/images/shipRadar.png',
  '673': 'https://s3.ca-central-1.amazonaws.com/c-core-public/ship-and-bergs-demo/Boat2.png',
  // '673': '/images/shipRadar.png',
  // '577': 'https://s3.ca-central-1.amazonaws.com/c-core-public/ship-and-bergs-demo/Boat3.png',
  '577': '/images/shipRadarAis.png',
}

const icebergUrls = [
  '/images/iceberg1.png',
  '/images/iceberg2.png',
  '/images/iceberg3.png',
  '/images/iceberg4.png',
]

const typeDescriptions = {
  '1022': ' with radar',
  '673': ' with optical',
  '577': ' with radar and AIS',
}

const opticalIds = [673]
const opticalWhitelist = ['Class', 'Detection Confidence', 'Predictive Value', 'Class Confidence', 'Validated By Secondary']

const Tooltip = ({ classes, feature, x, y, lng, lat }) => {
  const date = new Date('2019-03-17')
  const type = feature.properties.Class
  const targetId = feature.properties.TargetNum
  const imageUrl = imageUrls[targetId] || icebergUrls[targetId % 4]
  const optical = opticalIds.includes(targetId)
  return (
    <Fragment>
      {imageUrl &&
        <div className={classes.image} style={{ left: x - 300, top: y }}>
          <img src={imageUrl} alt={'ship'} width={300} />
        </div>
      }
      <div className={classes.tooltip} style={{left: x, top: y}}>
        
        <div>{type} detected {typeDescriptions[targetId] || ' with radar'}</div>
        {optical && <div> Sensor: Sentinel 2 </div>}
        <div>Date: { format(date, 'yyyy-MM-dd') }</div>
        <div>Longitude: { lng.toFixed(4) }</div>
        <div>Latitude: { lat.toFixed(4) }</div>
        {Object.keys(feature.properties).map(key => {          
          const value = feature.properties[key]
          if (optical) {
            if (opticalWhitelist.includes(key)) {
            return (
              <div key={key}>{key}: {value}</div>
            )
            } else {
              return null
            }
          } else {
          return (
            <div key={key}>{key}: {value}</div>
          )
          }
      })}
      </div>
    </Fragment>
  )
}

Tooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  feature: PropTypes.object.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
}

export default withStyles(styles)(Tooltip)
