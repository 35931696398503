import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { format } from 'date-fns'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '320px',
    background: '#fff',
    // boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
    // padding: '12px 24px',
    // margin: '20px',
    fontSize: '13px',
    lineHeight: '2',
    color: '#6b6b76',
    outline: 'none',
    textTransform: 'uppercase',
    zIndex: 10
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const TimeSelector = ({ classes, times, time, onChange = console.log }) => {
  return  (
    <form className={classes.root} autoComplete="off">
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="time-selector">Date</InputLabel>
        <Select
          value={time}
          onChange={onChange}
          inputProps={{
            name: 'time',
            id: 'time-selector',
          }}
        >
          {times.map(item => {
            return (
              <MenuItem key={item} value={item}>{format(item, 'yyyy-MM-dd')}</MenuItem>
            )
          })}
      </Select>
      </FormControl>
    </form>
  )
}

TimeSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  times: PropTypes.array
};

export default withStyles(styles)(TimeSelector)
