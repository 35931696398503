import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Immutable from "immutable"
import { SnackbarProvider } from 'notistack'

import Map from './components/Map'
import Panel from './components/Panel'
import TimeSelector from './components/TimeSelector'
import art from './components/art'
import './App.css'

class App extends Component {
  state = {
    time: new Date('2019-03-17').getTime(),
    times: [
      // new Date('2014-06-19').getTime(),
      // new Date('2016-09-07').getTime(),
      // new Date('2017-10-14').getTime(),
      // new Date('2018-09-12').getTime(),
      new Date('2019-03-17').getTime(),
    ],
    viewport: {
      latitude: 41.148346225890776,
      longitude: -104.78601505275526,
      zoom: 17.4
    },
    sources: {
      'fmv-cogs': [
        Immutable.fromJS({
          time: new Date('2014-06-19').getTime(),
          type: "raster",
          tiles: [`https://d3uoxf5ku9eg3c.cloudfront.net/fmv-cogs/2014-06-19/{z}/{x}/{y}.png`],
          tileSize: 512,
          scheme: 'tms',
          minzoom: 0,
          maxzoom: 19
        }),
        Immutable.fromJS({
          time: new Date('2016-09-07').getTime(),
          type: "raster",
          tiles: [`https://d3uoxf5ku9eg3c.cloudfront.net/fmv-cogs/2016-09-07/{z}/{x}/{y}.png`],
          tileSize: 512,
          scheme: 'tms',
          minzoom: 0,
          maxzoom: 19
        }),
        Immutable.fromJS({
          time: new Date('2017-10-14').getTime(),
          type: "raster",
          tiles: [`https://d3uoxf5ku9eg3c.cloudfront.net/fmv-cogs/2017-10-14/{z}/{x}/{y}.png`],
          tileSize: 512,
          scheme: 'tms',
          minzoom: 0,
          maxzoom: 19
        }),
        Immutable.fromJS({
          time: new Date('2018-09-12').getTime(),
          type: "raster",
          tiles: [`https://d3uoxf5ku9eg3c.cloudfront.net/fmv-cogs/2018-09-12/{z}/{x}/{y}.png`],
          tileSize: 512,
          scheme: 'tms',
          minzoom: 0,
          maxzoom: 19
        }),
        Immutable.fromJS({
          time: new Date('2019-03-17').getTime(),
          type: "raster",
          tiles: [`https://d3uoxf5ku9eg3c.cloudfront.net/fmv-cogs/2018-09-12/{z}/{x}/{y}.png`],
          tileSize: 512,
          scheme: 'tms',
          minzoom: 0,
          maxzoom: 19
        })
      ]
    },
    layers: []
  };

  componentDidMount () {
    console.log(art, "font-family:monospace; color:#005da6;")
  }

  handleTimeChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  render() {
    const { times, time } = this.state
    return (
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <>
          <CssBaseline />                    
          <Map {...this.state} />
          <Panel >
            <TimeSelector times={times} time={time} onChange={this.handleTimeChange} />
          </Panel>
        </>
      </SnackbarProvider>
    );
  }
}

export default App;
