import React, { Component } from 'react';
import MapGL, { Source, Layer, FeatureState } from '@urbica/react-map-gl';
import copy from 'copy-to-clipboard'
import { withSnackbar } from 'notistack'

import Tooltip from './Tooltip'
import 'mapbox-gl/dist/mapbox-gl.css';

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

class Map extends Component {
  state = {
    viewport: {
      latitude: 59.657,
      longitude: -47.267,
      zoom: 7.45
    },
  };

  componentWillMount () {
  }

  componentDidMount () {
    this.props.enqueueSnackbar('💡 Click detected item to copy ccordinates to clipboard.')
  }

  selectSource = ({ sourceId, time }) => {
    const sourceArray = this.props.sources[sourceId]
    const source = sourceArray.find(source => source.get('time') === time)

    return source
  }

  onHover = event => {
    const { features, point: { x, y }, lngLat: { lng, lat } } = event

    const hoveredFeature = features && features[0]

    event.target.getCanvas().style.cursor = 'pointer'
    this.setState({ hoveredFeature, x, y, lng, lat })
  }

  onLeave = event => {
    event.target.getCanvas().style.cursor = ''
    this.setState({ hoveredFeature: null })
  }

  onClick = event => {
    const { lngLat: { lng, lat } } = event

    const coordinates = `${lat}, ${lng}`

    this.props.enqueueSnackbar(`📋 Copied to clipboard: ${coordinates} .`)
    copy(coordinates)
  }

  renderTooltip() {
    const { hoveredFeature, x, y, lng, lat } = this.state;

    return hoveredFeature && (
      <Tooltip feature={hoveredFeature} x={x} y={y} lng={lng} lat={lat} />
    )
  }

  render() {

    return (
      <MapGL
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/light-v9"
        accessToken={accessToken}
        hash={true}
        onViewportChange={viewport => this.setState({ viewport })}
        {...this.state.viewport}
      >
        <Source
          id="fmv-vehicles-geojson"
          type="geojson"
          data={`https://s3.ca-central-1.amazonaws.com/c-core-public/ship-and-bergs-demo/BergsandBoats.geojson`}
          generateId={true}
        />
        <Layer
          onHover={this.onHover}
          onLeave={this.onLeave}
          onClick={this.onClick}
          id="fmv-vehicles-geojson"
          type="circle"
          source="fmv-vehicles-geojson"
          fill-opacity={[
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.5
          ]}
          paint={{
            "circle-radius": 10,
            'circle-color': [
              'match',
              ['get', 'TargetNum'],
              1022, 'yellow',
              673, 'red',
              577, 'green',
              'white'
            ]
            // 'circle-color': [
            //   'match',
            //   ['get', 'Class'],
            //   'ICEBERG', 'white',
            //   'SHIP', 'brown',
            //   'black'
            // ]
          }}
        />
        <FeatureState
          id={34}
          source="fmv-vehicles-geojson"
          state={{ hover: true }}
        />
        {this.renderTooltip()}
      </MapGL>
    )
  }
}

export default withSnackbar(Map)
