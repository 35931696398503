import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  card: {
    maxWidth: 345,
    position: 'absolute',
    top: 0,
    left: 0
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
})

function ImgMediaCard(props) {
  const { classes, children } = props;
  return (
    <Card className={classes.card}>
      <CardMedia
        component="img"
        alt="Ship and iceberg"
        className={classes.media}
        height="140"
        image="/images/icebreaker.jpg"
        title="Ship and iceberg detection"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Ship and Marine Hazard Surveillance
        </Typography>
        <Typography component="p">
          Ship and marine hazard detection using synthetic-aperature radar and optical data from satellites and other remote sensors.
        </Typography>
      </CardContent>
      <CardContent>
        {children}
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={() => window.open('https://s3.ca-central-1.amazonaws.com/c-core-public/ship-and-bergs-demo/ship-bergs-2019-03-17.geojson')}>
          Export data
        </Button>
        <Button size="small" color="primary" onClick={() => window.location='https://www.looknorth.org'}>
          Contact LOOKNorth
        </Button>
      </CardActions>
    </Card>
  );
}

ImgMediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImgMediaCard);
